<template>

  <div class="panel">
      <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
        <div class="columns is-mobile">
            <div class="column is-size-6 has-text-centered">COMPANY DETAILS</div>
        </div>
      </div>
      <hr>
      <div class="form" v-if="getActiveStore.role != 'STAFF'">
        <form action="" @submit.prevent="handleSubmit">

        <div class="columns">
            <div class="column">
                <b-field label="Store Name"
                    >
                    <b-input type="text"
                        icon="briefcase"
                        v-model="company.name"
                        maxlength="40"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Email">
                    <b-input type="email"
                        icon="envelope"
                        v-model="company.companyEmail"
                        maxlength="100"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Mobile Phone">
                    <b-input type="tel"
                        icon="mobile"
                        v-model="company.officeNumber"
                        maxlength="30"
                        required         
                        >
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="columns">

            <div class="column">
                <b-field label="First Name">
                    <b-input type="text"
                        icon="user-tie"
                        v-model="company.firstName"
                        maxlength="30"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>

            <div class="column">
                <b-field label="Last Name">
                    <b-input type="text"
                        icon="user-tie"
                        v-model="company.lastName"
                        maxlength="30"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>

            <div class="column">
                <b-field label="FEIN">
                    <b-input type="text"
                        icon="building"
                        v-model="company.fein"
                        maxlength="30"                   
                        >
                    </b-input>
                </b-field>
            </div>


            <div class="column">
                <b-field label="DUN">
                    <b-input type="text"
                        icon="building"
                        v-model="company.dun"
                        maxlength="30"                   
                        >
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <b-field label="Address">
                    
                    <b-input type="text"
                        icon="address-card"
                        v-model="company.addressLine1"
                        maxlength="30" 
                        required                  
                        >
                    </b-input>
                </b-field>   
            </div>
            <div class="column">
                <b-field label="City">
                    
                    <b-input type="text"
                        icon="address-card"
                        v-model="company.city"
                        maxlength="30" 
                        required                  
                        >
                    </b-input>
                </b-field>   
            </div>

            <div class="column">
                <!-- <b-field label="State">                            
                    <b-select
                        placeholder="State"
                        icon="map"
                        v-model="company.state">
                        <option
                            v-for="state in states"
                            :value="state[0]"
                            :key="state[0]">
                            {{ state[1] }}
                        </option>
                    </b-select>
                </b-field> -->
                <b-field label="State">
                    <b-select readonly v-model="company.state" placeholder="Select a State">
                        <option
                            v-for="option in states"
                            :value="option.code"
                            :key="option.code">
                            {{ option.code}} {{ option.name }} 
                        </option>
                    </b-select>
                </b-field>                

            </div>
            <div class="column">
                <b-field label="Zip">
                    
                    <b-input type="text"
                        icon-pack="fas"
                        icon="address-card"
                        v-model="company.zipCode"
                        maxlength="10"
                        required                   
                        >
                    </b-input>
                </b-field>
            </div>
            <div class="column">
            <b-field label="Country">
                <b-select 
                    placeholder="Select Country" 
                    v-model="company.country" 
                    required>
                    <option
                        v-for="country in countries"
                        :value="country[0]"
                        :key="country[0]">
                        {{ country[1] }}
                    </option>
                </b-select>		                               
            </b-field>
            </div>
        </div>
        <div>
            <error-message v-bind:messagetype='messagetype' v-bind:errors='errors'/>
            <button type="submit" class="button is-primary" :disabled='!formComplete'>Save</button>
            <p v-if="!formComplete">( Saving disabled temporarily )</p>
        </div>
        </form>

      </div>

        <div v-if="isLoading">
            <data-loading 
                :isLoading="isLoading" 
                :isFullPage="isFullPage"> 
            </data-loading>
        </div>

  </div>
    
</template>

<script>

import UserStore from "../../../store/UserStore"
// // import SelectStates from "../../../components/app/SelectStates"
import axios from 'axios'
import ErrorMessage from '../../../components/app/ErrorMessage'

import router from "../../../router"
import DataLoading from '../../../components/app/DataLoading'

export default {
    components: {
        DataLoading,
        ErrorMessage
    },

    metaInfo: {
        title: 'Update Company Details'
    },

    data() {
        return {
            isLoading: false,
            isFullPage: true,
            showForm: true,
            formComplete: true,
            errors: [],
            company: [],
            states: [],
            companyStateCode: "",
            // states: [ [ 'FL', 'Florida'], [ 'IL', 'Illinois'], [ 'IN', 'Indiana'],  [ 'MI', 'Michigan'], [ 'WI', 'Wisconsin']],
            countries: [ ['US', 'USA'], ['CA','Canada'], ['MX','Mexico']],
            zones: [ ['CST','Central Time'], ['EST', 'Eastern Time'], ['MST','Mountain Time'], ['PST','Pacefic Time']],
            messagetype: 'is-warning',

        }
    },

    methods: {

        fetchCompany() {
			if (  UserStore.getters.getActiveStore.companyId ) {
				axios.get('/sapi/companies/' + UserStore.getters.getActiveStore.companyId, {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken					},				
                    })
				.then( response => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                    this.company = response.data.data
				})
				.catch( (err) => {
					if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)
				})				
			}
        },

        handleSubmit() {
			if (  UserStore.getters.getActiveStore.companyId ) {
                this.isLoading=true

				axios.put('/sapi/companies', 
                    this.company,
                    {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken					},				
                    })
				.then( response => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)

                    if (response.status === 200) {
                        this.postSuccess = true
                        this.$buefy.notification.open({
                                message: 'Company Information Updated',
                                type: 'is-success',
                                duration: 1000,
                                'has-icon': true,
                                'icon-pack': 'fas',
                                icon: 'exclamation-circle'
                            })
                    }

                    setTimeout(function() {
                        router.push({ path: '/' })
                    }, 1000);

                    
				})
				.catch( (err) => {
					if (process.env.VUE_APP_DEBUG == 'true') console.log("Error",err)
				})				
			}
        },

        countryStates() {
            
            var url = process.env.VUE_APP_API_ROOT_URL + "/global/states/" + UserStore.getters.getStore.country
            // console.log(url)

            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                    // this.states = response.data.data

                    this.states = response.data.data.sort(function(a,b) {

                            return (a["code"] > b["code"]) ? 1 : (a["code"] < b["code"]) ? -1 : 0;

                        });
                    var storeObj = this.states.find(o => o.code === UserStore.getters.getStore.state)
                    this.storeStateCode = storeObj.code

                    this.isLoading = false
                })
                .catch( (err) => {
                    this.records = []
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                    this.isLoading = false
                    if (err.response.status === 403) {
                        alert('Session Expired. Please log in again!')
                        UserStore.commit('userLogout')
                    }
                })            
            

        }

    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

    },

    mounted() {
        if (UserStore.getters.getActiveStore.role != 'STAFF') {
            this.countryStates()
            this.fetchCompany()
        }
    }


    
    
}
</script>