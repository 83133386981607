<template>
    <b-message 
        :type="messagetype" has-icon icon-pack='fas' v-if="errors.length">
        <ul>
        <li v-for="error in errors" v-bind:key='error'>{{ error }}</li>
        </ul>
    </b-message>    
</template>

<script>
export default {
    props: {
        errors: Array,
        messagetype: String
    }
}
</script>